const useCategory = reference => {
  const categories = Object.keys(reference).map(key => {
    let category
    if (reference[key]) {
      switch (key) {
        case "waterproofingBefore":
          category = {
            name: "防水工事",
            slug: "waterproofing"
          }
          break
        case "sealingBefore":
          category = {
            name: "シーリング工事",
            slug: "sealing"
          }
          break
        case "paintingBefore":
          category = {
            name: "塗装工事",
            slug: "painting"
          }
          break
        default:
          break
      }
    }
    return category
  }).filter(category => category !== undefined)
  return categories
}

export default useCategory
