import React, { useState } from "react"
import styled from "styled-components"
// modules
import useCategory from "../../modules/use_category"
// components
import BeforeAfter from "./before_after"
import UnorderedList from "../atoms/unordered_list"

const Wrapper = styled.div`
  padding: ${props => props.padding};
`

const ListItem = styled.li`
  border-bottom: 3px solid #e7e7eb;
  border-bottom-color: ${props => props.borderBottomColor};
  cursor: pointer;
  flex: 1;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.5;
  padding: 10px 0 7px;
  text-align: center;

  &:hover {
    border-bottom-color: #26610f;
    color: #26610f;
  }
`

const Case = ({
  reference,
  content
}) => {
  const categories = useCategory(reference)
  const initialState = categories.length > 0 ? categories[0].slug : null
  const [current, setCurrent] = useState(initialState)
  return (
    <>
      <BeforeAfter
        asymmetric={true}
        imageBefore={reference.before.url}
        altBefore="" //
        imageAfter={reference.after.url}
        altAfter="" //
      />
      <Wrapper padding="20px 0 10px">
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: `${content}` }}
        />
      </Wrapper>
      {categories.length > 0 &&
        <div>
          <UnorderedList display="flex">
            {categories.map(category => {
              const {
                name,
                slug
              } = category
              return (
                <ListItem
                  key={name}
                  borderBottomColor={slug === current && "#26610f"}
                  onClick={() => setCurrent(slug)}
                >
                  {name}
                </ListItem>
              )
            })}
          </UnorderedList>
          <Wrapper padding="20px">
            <BeforeAfter
              imageBefore={reference[`${current}Before`].url}
              altBefore="" //
              imageAfter={reference[`${current}After`].url}
              altAfter="" //
            />
          </Wrapper>
        </div>
      }
    </>
  )
}

export default Case
