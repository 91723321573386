import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
// components
import Layout from "../components/layout"
import TitleLeft from "../components/molecules/title_left"
import Paragraph from "../components/atoms/paragraph"
import Case from "../components/molecules/case"
import SEO from "../components/seo"

const StyledBlogPost = styled.div`
  background: #e8ecef;
  display: grid;
  grid-column-end: -1;
  grid-column-start: 1;
  grid-template-columns: inherit;
  grid-template-rows: inherit;
  padding: 15px 0;
`

const OuterWrapper = styled.div`
  background: #ffffff;
  box-sizing: border-box;
  grid-column-end: -2;
  grid-column-start: 2;
  min-height: calc(100vh - 169px);
  padding: 25px;

  @media (max-width: 767px) {
    min-height: calc(100vh - 137px);
  }
`

const InnerWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const BlogPost = ({
  data,
  location
}) => {
  const {
    title,
    date,
    formattedDate,
    content,
    ...rest
  } = data.microcmsBlog
  return (
    <Layout
      location={location}
      showContact={true}
    >
      <StyledBlogPost>
        <OuterWrapper>
          <article>
            <TitleLeft
              level={1}
              fontSize="20px"
              heading={title}
            />
            <InnerWrapper>
              <aside>
                <time
                  dateTime={date}
                  style={{
                    fontSize: "15px",
                    lineHeight: 1.5
                  }}
                >
                  {formattedDate}
                </time>
                <Paragraph
                  background="#e7e7eb"
                  fontSize="small"
                  padding="5px 0"
                  textAlign="center"
                  paragraph="施工事例"
                />
              </aside>
            </InnerWrapper>
            <Case
              {...rest}
              content={content}
            />
          </article>
        </OuterWrapper>
      </StyledBlogPost>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query($id: String!) {
    microcmsBlog(id: { eq: $id }) {
      title
      date
      formattedDate: date(formatString: "YYYY年MM月DD日")
      content
      reference {
        before {
          url
        }
        after {
          url
        }
        waterproofingBefore {
          url
        }
        waterproofingAfter {
          url
        }
        sealingBefore {
          url
        }
        sealingAfter {
          url
        }
        paintingBefore {
          url
        }
        paintingAfter {
          url
        }
      }
    }
  }
`

export const Head = ({
  data,
  location
}) => {
  const {
    content,
    title
  } = data.microcmsBlog
  const description = content.replace(/<.+?>/g, "")
  return (
    <SEO
      title={`${title} | 施工事例 | ブログ`}
      description={
        description.length <= 70
          ? description
          : `${description.slice(0, 70)}…`
      }
      pathname={location.pathname}
    />
  )
}
